// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-berco-venus-js": () => import("./../src/pages/berço-venus.js" /* webpackChunkName: "component---src-pages-berco-venus-js" */),
  "component---src-pages-coming-soon-1-js": () => import("./../src/pages/coming-soon-1.js" /* webpackChunkName: "component---src-pages-coming-soon-1-js" */),
  "component---src-pages-coming-soon-2-js": () => import("./../src/pages/coming-soon-2.js" /* webpackChunkName: "component---src-pages-coming-soon-2-js" */),
  "component---src-pages-guia-interior-js": () => import("./../src/pages/guia-interior.js" /* webpackChunkName: "component---src-pages-guia-interior-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labirinto-js": () => import("./../src/pages/labirinto.js" /* webpackChunkName: "component---src-pages-labirinto-js" */),
  "component---src-pages-mente-certa-js": () => import("./../src/pages/mente-certa.js" /* webpackChunkName: "component---src-pages-mente-certa-js" */),
  "component---src-pages-mundo-sem-origem-js": () => import("./../src/pages/mundo-sem-origem.js" /* webpackChunkName: "component---src-pages-mundo-sem-origem-js" */),
  "component---src-pages-sintese-radiante-js": () => import("./../src/pages/sintese-radiante.js" /* webpackChunkName: "component---src-pages-sintese-radiante-js" */),
  "component---src-pages-toda-materia-js": () => import("./../src/pages/toda-materia.js" /* webpackChunkName: "component---src-pages-toda-materia-js" */),
  "component---src-pages-verbo-sideral-js": () => import("./../src/pages/verbo-sideral.js" /* webpackChunkName: "component---src-pages-verbo-sideral-js" */)
}

